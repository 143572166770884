




















































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {Action, Getter} from 'vuex-class';
import {IApiTalentQuiz} from '@/shared/models/IApiTalentQuiz';
import {DeveloperRoleInfo} from '@/shared/constants/ROLES';
import {QUIZ_SERVICE} from '@/shared/services/quiz.service';
import {QUIZ_STATES} from '@/shared/constants/QUIZ_STATES';
import QuizModule from '@/modules/quiz';
import {QuizRouter} from '@/modules/quiz/router';
import {QuizActions} from '@/modules/quiz/store/actions';
import {QuizGetters} from '@/modules/quiz/store/getters';
import RsLoading from '@/shared/components/loading/loading.vue';
import RsCountdown from '@/shared/components/countdown/countdown.vue';
import {Icons} from '@/shared/components/icon/icons';


@Component({
  metaInfo: {
    title: 'Assesments'
  },
  components: {RsCountdown, RsLoading, RsButton, RsIcon}
})
export default class AssesmentsIndex extends Vue {
  @Action(QuizActions.GET_QUIZZES, {namespace: QuizModule.namespace}) getQuizzes: () => void;
  @Getter(QuizGetters.GET_QUIZZES, {namespace: QuizModule.namespace}) quizzes: IApiTalentQuiz[];

  QUIZ_STATES = QUIZ_STATES;
  QuizRouter = QuizRouter;
  Icons = Icons;

  get generalQuizzes(){
    return this.quizzes.filter((q: IApiTalentQuiz) => !q.skills || q.skills.length <= 0);
  }

  get languageQuizzes(){
    return this.quizzes.filter((q: IApiTalentQuiz) => q.skills?.length > 0);
  }

  roleData(quiz: IApiTalentQuiz): DeveloperRoleInfo {
    return QUIZ_SERVICE.roleData(quiz);
  }

  quizState(quiz: IApiTalentQuiz): QUIZ_STATES {
    return QUIZ_SERVICE.quizState(quiz);
  }

  mounted() {
    this.$track.sendEvent('AssesmentsPage')
    this.getQuizzes();
  }
}
